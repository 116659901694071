import React from 'react';
import "react-circular-progressbar/dist/styles.css";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2 data-aos="fade-up"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true">About me</h2>
	                <div className="space-border"
	                	data-aos="fade-up"
                        data-aos-delay="20"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
	                	></div>
	            </div>
	            <div className="col-md-8 text-center m-auto"
	            	data-aos="fade-up"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
	            	>
	             <p style={{fontSize: '1.4rem'}}>I am Leonte Bogdan, known in the art world as Leo, a tattoo artist from Bacău, 31 years old, passionate about transforming art into permanent expressions on skin. With a distinctive style and a steady hand, I have completed over a thousand tattoo projects, each telling a unique story. I began my artistic journey more than a decade ago, and since then, every tattoo made is a reflection of my dedication to art and to each client individually. I strive to always offer a personalized experience and put my soul into every line and shade I create. For my complete portfolio and to schedule a session, I invite you to browse this site. I look forward to every artistic challenge and the opportunity to bring dreams to life on skin.</p>
	            </div>
			</div>

		</div>
		</div>
	);
}

export default hero;