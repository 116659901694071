import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery = function () {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = () => {
        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose = () => {
        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = () => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = () => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = () => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = () => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };
    return (
        <div className="container">
            <GlobalStyles />
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>Projects</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <Masonry
                className={"row my-gallery-class"} elementType={"div"}>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Images</h3>
                            <h5 className="d-tag">Tattoo's</h5>
                        </div>
                        <img src="./img/gallery/IMG-20231109-WA0010.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Video</h3>
                            <h5 className="d-tag">Tattoo's</h5>
                        </div>
                        <img src="./img/gallery/IMG-20231109-WA0018.jpg" alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Muzic</h3>
                            <h5 className="d-tag">Youtube</h5>
                        </div>
                        <img src="./img/music-gallery/Screenshot 2023-11-14 123246.png" alt="gallery" />
                    </div>
                </div>


            </Masonry>

           

            {/* lightbox Images all */}
            {lighbx1 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="row g-4">
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0005.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0007.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0008.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0009.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0010.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0011.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0012.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0013.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0014.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0015.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0016.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0017.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0018.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0019.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0020.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0021.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0022.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0023.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0024.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0025.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0026.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0027.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0028.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0029.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0030.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0031.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0032.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0033.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0034.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0035.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0036.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0037.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0039.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>

                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0041.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0042.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0043.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0044.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0045.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0047.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0048.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0049.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0050.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0051.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0052.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0053.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0054.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12  item">
                                            <img src="./img/gallery/IMG-20231109-WA0055.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* lightbox Videos all  */}
            {lighbx2 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <video className="pop" controls autoPlay loop>
                                        <source src="./video/local-video-3.mp4" type="video/mp4" />
                                    </video>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            )}

             {/* lightbox Music Youtube */}
             {lighbx && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div style={{marginBottom: '2em'}} className="col-lg-12">
                                    <iframe width="100%" height="700" src="https://www.youtube.com/embed/k7yesWoE3qs?si=YX3Lz4EtHiPQqmYT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Gallery;